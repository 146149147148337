/* eslint-disable no-extend-native */
import { ICurrency } from './currency';

export {};

declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Date {
        getSQL120(): string;
    }
}

// localDateOffset=(new Date(data.serverTime)).getTime()-(new Date()).getTime()  -- FIXME this should be in the global state to fix issue with some browsers that need help converting to a date.
Date.prototype.getSQL120 = function () {
    // if(typeof localDateOffset==="undefined"){localDateOffset=0}
    const localDateOffset = 0;
    const d = new Date(this.getTime() + (localDateOffset || 0));

    function fn_pad(aStr: string | number, aChar: string, len: number) {
        aStr = aStr.toString();
        if (aStr.length < len) {
            aStr = Array(len + 1 - aStr.length).join(aChar) + aStr;
        }
        return aStr;
    }
    return (
        d.getFullYear() +
        '-' +
        fn_pad(d.getMonth() + 1, '0', 2) +
        '-' +
        fn_pad(d.getDate(), '0', 2) +
        ' ' +
        fn_pad(d.getHours(), '0', 2) +
        ':' +
        fn_pad(d.getMinutes(), '0', 2)
    );
};

declare global {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    interface Window {
        flash_uid: number;
        maintain_currency?: ICurrency;
        newrelic?: {
            setCustomAttribute: ($name: string, $value: string | number) => void;
            noticeError: ($error: Error, $customAttributes?: object) => void;
        };
        maintain_baseUrl?: string;
        wootric: (command: 'run' | 'stop') => void;
        opera?: string; // added both opera and MSStream as needed for browser check for wootric
        MSStream?: unknown;
        maintain_subscriptionID?: string; // added to store the subscriptionID in the window object
        maintain_location?: {
            href: string;
            origin: string;
            protocol: string;
            host: string;
            hostname: string;
            port: string;
            pathname: string;
            search: string;
            hash: string;
        };
        clarity?: (command: string, ...args: string[]) => void;
    }
}

let flashUID: number;
if (window.localStorage) {
    flashUID = Number(localStorage.getItem('flash_uid'));
    if (!flashUID) {
        flashUID = Math.round(Math.random() * 100000000);
        localStorage.setItem('flash_uid', flashUID.toString());
    }
} else {
    flashUID = Math.round(Math.random() * 100000000);
}
Window.prototype.flash_uid = flashUID;
