import React, { useEffect, useState } from 'react';
import getGlobalValues from '../../Services/API/GetGlobalValues';
import { Trans } from '@lingui/macro';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

const Clarity = () => {
    const [consentGiven, setConsentGiven] = useState<boolean | null>(null);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (consent === 'true' && window.clarity) {
            setConsentGiven(true);
            window.clarity('consent');
        } else if (consent === 'false') {
            setConsentGiven(false);
        }

        const fetchGlobalValues = async () => {
            const globalValues = await getGlobalValues();
            if (window.clarity && globalValues) {
                // eslint-disable-next-line lingui/no-unlocalized-strings
                window.clarity('set', 'UserType', globalValues.nv_UserType);
            }
        };

        fetchGlobalValues();

        const handleConsentGranted = () => {
            if (window.clarity) {
                window.clarity('consent');
            }
        };

        window.addEventListener('consentGranted', handleConsentGranted);

        return () => {
            window.removeEventListener('consentGranted', handleConsentGranted);
        };
    }, []);

    const handleCookieAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setConsentGiven(true);
        const event = new Event('consentGranted');
        window.dispatchEvent(event);
    };

    const handleCookieDeny = () => {
        localStorage.setItem('cookieConsent', 'false');
        setConsentGiven(false);
    };
    return (
        <I18nProvider i18n={i18n}>
            {consentGiven === null && localStorage.getItem('cookieConsent') === null && (
                <div
                    style={{
                        position: 'fixed',
                        bottom: '0',
                        left: '0',
                        right: '0',
                        margin: 'auto',
                        marginBottom: '20px',
                        width: '75%',
                        background: '#f0f0f0',
                        color: 'black',
                        padding: '15px',
                        zIndex: '200000',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderRadius: '8px',
                        boxShadow: '0px 1px 32px 0px rgb(0 0 0 / 80%)',
                    }}
                >
                    <div style={{ flexGrow: 1, paddingRight: '30px' }}>
                        <Trans>
                            We use cookies to enhance your browsing experience, serve personalised
                            content, and analyse our traffic. By clicking "Accept", you consent to
                            our use of cookies.
                        </Trans>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <button
                            onClick={handleCookieDeny}
                            style={{
                                marginLeft: '10px',
                                width: '100px',
                                border: 'none',
                                color: 'teal',
                                padding: '5px',
                                borderRadius: '4px',
                            }}
                        >
                            <Trans>Decline</Trans>
                        </button>
                        <button
                            onClick={handleCookieAccept}
                            style={{
                                width: '100px',
                                border: '1px solid teal',
                                color: 'white',
                                padding: '5px',
                                backgroundColor: 'teal',
                                borderRadius: '4px',
                            }}
                        >
                            <Trans>Accept</Trans>
                        </button>
                    </div>
                </div>
            )}
        </I18nProvider>
    );
};

export default Clarity;
