/* eslint-disable lingui/no-unlocalized-strings */
import en from './FlagImgs/en.png';
import el from './FlagImgs/el.png';
import sv from './FlagImgs/sv.png';
import is from './FlagImgs/is.png';
import it from './FlagImgs/it.png';
import de from './FlagImgs/de.png';
import fr from './FlagImgs/fr.png';
import da from './FlagImgs/da.png';
import nl from './FlagImgs/nl.png';
import bg from './FlagImgs/bg.png';
import hr from './FlagImgs/hr.png';
import cs from './FlagImgs/cs.png';
import hu from './FlagImgs/hu.png';
import lt from './FlagImgs/lt.png';
import pl from './FlagImgs/pl.png';
import pt from './FlagImgs/pt.png';
import ro from './FlagImgs/ro.png';
import sk from './FlagImgs/sk.png';
import lv from './FlagImgs/lv.png';
import mt from './FlagImgs/mt.png';
import no from './FlagImgs/no.png';
import sl from './FlagImgs/sl.png';
import es from './FlagImgs/es.png';
import et from './FlagImgs/et.png';

export interface ILanguagesAvailable {
    id: string;
    name: string;
    isTranslated: boolean;
    icon?: string;
}

export const getAvailableLanguages = () => {
    // change isTranslated to true for the languages when they are translated
    const languages: ILanguagesAvailable[] = [
        { id: 'en', name: 'English', isTranslated: true, icon: en },
        { id: 'de', name: 'Deutsch', isTranslated: true, icon: de },
        { id: 'fr', name: 'Français', isTranslated: true, icon: fr },
        { id: 'nl', name: 'Nederlands', isTranslated: true, icon: nl },
        { id: 'bg', name: 'Български', isTranslated: true, icon: bg },
        { id: 'hr', name: 'Hrvatski', isTranslated: true, icon: hr },
        { id: 'el', name: 'Ελληνικά', isTranslated: true, icon: el },
        { id: 'cs', name: 'Čeština', isTranslated: true, icon: cs },
        { id: 'da', name: 'Dansk', isTranslated: true, icon: da },
        { id: 'et', name: 'Eesti', isTranslated: true, icon: et },
        { id: 'hu', name: 'Magyar', isTranslated: true, icon: hu },
        { id: 'is', name: 'Íslenska', isTranslated: true, icon: is },
        { id: 'it', name: 'Italiano', isTranslated: true, icon: it },
        { id: 'lv', name: 'Latviešu', isTranslated: true, icon: lv },
        { id: 'lt', name: 'Lietuvių', isTranslated: true, icon: lt },
        { id: 'mt', name: 'Malti', isTranslated: true, icon: mt },
        { id: 'no', name: 'Norsk', isTranslated: true, icon: no },
        { id: 'pl', name: 'Polski', isTranslated: true, icon: pl },
        { id: 'pt', name: 'Português', isTranslated: true, icon: pt },
        { id: 'ro', name: 'Română', isTranslated: true, icon: ro },
        { id: 'sk', name: 'Slovenský', isTranslated: true, icon: sk },
        { id: 'sl', name: 'Slovenščina', isTranslated: true, icon: sl },
        { id: 'es', name: 'Español', isTranslated: true, icon: es },
        { id: 'sv', name: 'Svenska', isTranslated: true, icon: sv },
        { id: 'pseudo-en', name: 'Pseudo-English', isTranslated: true },
    ];
    const translatedLanguages = languages.filter(
        (language) =>
            language.isTranslated &&
            !(
                (
                    process.env.NODE_ENV === 'production' &&
                    language.id === 'pseudo-en' &&
                    !window.location.hostname.includes('preprod')
                ) // preprod comes under production but we want to show pseudo-en in preprod
            ),
    );
    return translatedLanguages;
};
